<template>
  <div>
    <b-alert v-if="getErrorMsg || error" show variant="danger"
      >{{ getErrorMsg }} {{ error }}</b-alert
    >
    <div v-if="getLoadingItemState || isUpdating">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section v-else>
      <b-form @submit="onSubmit">
        <p><strong>ItemNo:</strong> {{ getItem.itemNo }}</p>
        <p><strong>Id:</strong> {{ getItem.id }}</p>

        <b-form-group id="input-group-1" label="Item name:" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="getItem.name"
            type="text"
            required
            placeholder="name"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-3"
          label="description:"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            v-model="getItem.description"
            required
            autocomplete="false"
            placeholder="Description"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-4"
          label="Special TXT:"
          label-for="input-4"
        >
          <!-- <b-form-input
            id="input-4"
            v-model="item.itemMetaData[0].value"
            required
            autocomplete="false" 
            placeholder="Special TXT"
            ></b-form-input> -->
        </b-form-group>

        <multiselect
          v-model="getItem.specialTxt"
          :options="
            getSpecialTxt.map(x => {
              return { name: x.name };
            })
          "
          :close-on-select="false"
          label="name"
          track-by="name"
          :multiple="true"
        >
        </multiselect>

        <b-form-group
          id="input-group-polish"
          label="Polish type:"
          label-for="input-polish"
        >
          <!-- <b-form-input
            id="input-4"
            v-model="item.itemMetaData[0].value"
            required
            autocomplete="false" 
            placeholder="Special TXT"
            ></b-form-input> -->
        </b-form-group>

        <multiselect
          v-model="getItem.polishType"
          :options="
            getPolishTypes.map(x => {
              return { name: x.name };
            })
          "
          label="name"
          track-by="name"
          :close-on-select="false"
          :multiple="true"
        >
        </multiselect>

        <!-- <b-form-group id="input-group-4" 
            label="Item type:" 
            label-for="input-4">
            <b-form-input
            id="input-4"
            v-model="item.itemType"
            required
            list="my-list-id" 
            placeholder="Select a type:"
            ></b-form-input>
            <datalist id="my-list-id">
                <option v-for="type in itemTypes" :key="type">{{ type }}</option>
            </datalist>
        </b-form-group>  -->

        <!-- <b-form-group 
            id="input-group-suppliers"
        label="Supplier:" 
            label-for="supplier"
        >

            <b-form-input id="supplier"
                    v-model="item.supplier"
                    required
                    list="my-list-supplier" 

            >
            </b-form-input>
            <datalist id="my-list-supplier">
                <option v-for="s in suppliers" :key="s">{{ s }}</option>
            </datalist>
        </b-form-group> -->

        <p></p>

        <b-button type="submit" variant="primary">Save</b-button>
        <b-button type="reset" class="ml-3" variant="danger">Reset</b-button>
        <Back-Button class="ml-3"></Back-Button>
      </b-form>
    </section>
    <!-- <b-card class="mt-3" header="Form Data Result">
      <pre class="m-0">{{ getItem }}</pre>
    </b-card> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import BackButton from "@/components/Buttons/BackButton";

export default {
  name: "itemForm",
  components: {
    Multiselect,
    BackButton
  },
  data() {
    return {
      itemTypes: ["Normal", "Glass", "Other"],
      suppliers: ["NA"],
      isUpdating: false,
      error: null
    };
  },
  methods: {
    ...mapActions(["addItem", "setSelectedItem", "getItems", "loadMetaData"]),
    onSubmit(e) {
      this.isUpdating = true;
      e.preventDefault();
      this.addItem(this.getItem)
        .then(() => {
          this.isUpdating = false;
          this.$router.push("/Admin/items/all");
        })
        .catch(error => {
          this.isUpdating = false;
          this.error = error;
        });
    }
  },
  computed: mapGetters([
    "getItem",
    "loading",
    "getLoadingItemState",
    "getSpecialTxt",
    "getPolishTypes",
    "getErrorMsg"
  ]),
  created() {
    if (this.$route.params.id) {
      this.setSelectedItem(this.$route.params.id);
    } else {
      // no ID = new item form (reset item)
      this.setSelectedItem("");
    }
    this.loadMetaData();
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
